import React from "react";
import useDarkMode from "@fisch0920/use-dark-mode";

export const DarkModeContext = React.createContext();

const DarkModeProvider = ({ children }) => {
	const darkMode = useDarkMode(true);

	return <DarkModeContext.Provider value={darkMode}>{children}</DarkModeContext.Provider>;
};

export default DarkModeProvider;
