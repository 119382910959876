import React, { useContext } from "react";
import "./styles/App.css";
import "./styles/DarkMode.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Spotify } from "react-spotify-embed";
import Select from "react-select";
import SearchBar from "./components/SearchBar";
import DarkModeToggle from "./components/DarkModeToggle";
import DarkModeProvider from "./components/DarkModeProvider";

const CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;

function App() {
	const [searchInput, setSearchInput] = useState("");
	const [accessToken, setAccessToken] = useState("");
	const [albums, setAlbums] = useState([]);
	const [artists, setArtists] = useState([]);
	const [playlists, setPlaylists] = useState([]);
	const [tracks, setTracks] = useState([]);
	const [selectedAlbum, setSelectedAlbum] = useState(null);
	const [selectedArtist, setSelectedArtist] = useState(null);
	const [selectedPlaylist, setSelectedPlaylist] = useState(null);
	const [selectedTrack, setSelectedTrack] = useState(null);
	const [sortOption, setSortOption] = useState(null);

	const darkMode = useContext(DarkModeProvider);

	useEffect(() => {
		// Fetch API Access Token
		var authParams = {
			method: "POST",
			headers: {
				"Content-Type": "application/x-www-form-urlencoded",
			},
			body: "grant_type=client_credentials&client_id=" + CLIENT_ID + "&client_secret=" + CLIENT_SECRET,
		};

		fetch("https://accounts.spotify.com/api/token", authParams)
			.then((result) => result.json())
			.then((data) => setAccessToken(data.access_token));
	}, []);

	// Search
	async function search() {
		var searchParams = {
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`,
				"Content-Type": "application/json",
			},
		};

		var searchResults = await fetch("https://api.spotify.com/v1/search?q=" + searchInput + "&type=artist,album,playlist,track&limit=9", searchParams)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				return data;
			})
			.catch((error) => {
				console.error("Uncaught API Error: ", error);
			});

		if (searchInput.trim() === "") {
			setAlbums([]);
			setArtists([]);
			setPlaylists([]);
			setTracks([]);
		} else {
			setAlbums(searchResults.albums.items);
			setArtists(searchResults.artists.items);
			setPlaylists(searchResults.playlists.items);
			setTracks(searchResults.tracks.items);
		}
	}

	function formatDate(dateString) {
		const options = { year: "numeric", month: "short", day: "numeric" };
		return new Date(dateString).toLocaleDateString(undefined, options);
	}

	async function onCardClick(type, id) {
		var clickParams = {
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`,
				"Content-Type": "application/json",
			},
		};

		await fetch(`https://api.spotify.com/v1/${type}s/${id}`, clickParams)
			.then((response) => response.json())
			.then((data) => {
				handleType(type, data);
			});
	}

	async function onSelectChanged(type, selectedSortOption) {
		if (type === "track") {
			if (selectedSortOption) {
				if (selectedSortOption.value === "popularity") {
					const tracksByPopularity = [...tracks].sort((a, b) => a.popularity - b.popularity);
					setTracks(tracksByPopularity);
				} else if (selectedSortOption.value === "name") {
					const tracksByName = [...tracks].sort((a, b) => a.name.localeCompare(b.name));
					setTracks(tracksByName);
				}
			} else {
				setTracks([...tracks]);
			}
		} else if (type === "artist") {
			if (selectedSortOption) {
				if (selectedSortOption.value === "popularity") {
					const artistsByPopularity = [...artists].sort((a, b) => b.popularity - a.popularity);
					setArtists(artistsByPopularity);
				} else if (selectedSortOption.value === "followers") {
					const artistsByFollowers = [...artists].sort((a, b) => b.followers.total - a.followers.total);
					setArtists(artistsByFollowers);
				}
			} else {
				setArtists([...artists]);
			}
		}
	}

	function closeModal(type) {
		handleType(type, null);
	}

	function handleType(type, data) {
		switch (type) {
			case "album":
				setSelectedAlbum(data);
				break;
			case "artist":
				setSelectedArtist(data);
				break;
			case "playlist":
				setSelectedPlaylist(data);
				break;
			case "track":
				setSelectedTrack(data);
				break;
			default:
				console.error("Invalid type " + type);
				break;
		}
	}

	return (
		<DarkModeProvider>
			<div className="App">
				<Container className="pt-3">
					<SearchBar searchInput={searchInput} setSearchInput={setSearchInput} search={search} />
					<DarkModeToggle />
				</Container>

				{/* Albums Container */}
				<Container>
					<Row className="mx-0">
						<h2>Albums</h2>
						{albums && albums.length > 0 ? (
							albums.map((album, i) => {
								return (
									<Col key={i} xs={12} sm={6} md={4} lg={3} className="mb-3 mt-3">
										<Card className={`${darkMode ? "dark-mode" : "light-mode"}`} onClick={() => onCardClick("album", album.id)} style={{ cursor: "pointer" }}>
											<Card.Img src={album.images[0].url}></Card.Img>
											<Card.Body>
												<Card.Title>{album.name}</Card.Title>
												<Card.Subtitle className="py-2">{album.artists[0].name}</Card.Subtitle>
												<Card.Footer> {formatDate(album.release_date)}</Card.Footer>
											</Card.Body>
										</Card>
									</Col>
								);
							})
						) : (
							<p>No Albums Found</p>
						)}
					</Row>
				</Container>

				{/* Artists Container */}
				<Container>
					<Row className="mx-0">
						<h2>Artists</h2>
						{tracks.length > 0 && (
							<>
								<Select
									name="artistSelect"
									placeholder="Sort By..."
									options={[
										{ value: "popularity", label: "Popularity" },
										{ value: "followers", label: "Followers" },
									]}
									isClearable={true}
									isSearchable={true}
									onChange={(artistSortOption) => {
										setSortOption(artistSortOption);
										onSelectChanged("artist", artistSortOption);
									}}
									value={sortOption}
								></Select>
							</>
						)}
						{artists && artists.length > 0 ? (
							artists.map((artist, i) => {
								return (
									<Col key={i} xs={12} sm={6} md={4} lg={3} className="mb-3 mt-3">
										<Card onClick={() => onCardClick("artist", artist.id)} style={{ cursor: "pointer" }}>
											<Card.Img src={artist?.images[0]?.url}></Card.Img>
											<Card.Body>
												<Card.Title>{artist.name}</Card.Title>
												<Card.Subtitle className="py-2">
													{artist.genre}
													{"Popularity: " + artist.popularity}
												</Card.Subtitle>
												<Card.Footer> {"Followers: " + artist.followers.total}</Card.Footer>
											</Card.Body>
										</Card>
									</Col>
								);
							})
						) : (
							<p>No Artists Found</p>
						)}
					</Row>
				</Container>

				{/* Playlists Container */}
				<Container>
					<Row className="mx-0">
						<h2>Playlists</h2>
						{playlists && playlists.length > 0 ? (
							playlists.map((playlist, i) => {
								return (
									<Col key={i} xs={12} sm={6} md={4} lg={3} className="mb-3 mt-3">
										<Card onClick={() => onCardClick("playlist", playlist.id)} style={{ cursor: "pointer" }}>
											<Card.Img src={playlist?.images[0]?.url}></Card.Img>
											<Card.Body>
												<Card.Title>{playlist.name}</Card.Title>
												<Card.Subtitle className="py-2">{playlist.name}</Card.Subtitle>
												<Card.Footer>{playlist.owner.display_name}</Card.Footer>
											</Card.Body>
										</Card>
									</Col>
								);
							})
						) : (
							<p>No Playlists Found</p>
						)}
					</Row>
				</Container>

				{/* Tracks Container */}
				<Container>
					<Row className="mx-0">
						<h2>Tracks</h2>
						{tracks.length > 0 && (
							<>
								<Select
									name="trackSelect"
									placeholder="Sort By..."
									options={[
										{ value: "popularity", label: "Popularity" },
										{ value: "name", label: "Name" },
									]}
									isClearable={true}
									isSearchable={true}
									onChange={(trackSortOption) => {
										setSortOption(trackSortOption);
										onSelectChanged("track", trackSortOption);
									}}
									value={sortOption}
								></Select>
							</>
						)}
						{tracks && tracks.length > 0 ? (
							tracks.map((track, i) => {
								return (
									<Col key={i} xs={12} sm={6} md={4} lg={3} className="mb-3 mt-3">
										<Card onClick={() => onCardClick("track", track.id)} style={{ cursor: "pointer" }}>
											<Card.Img src={track?.album?.images[0]?.url}></Card.Img>
											<Card.Body>
												<Card.Title>{track.name}</Card.Title>
												<Card.Subtitle className="py-2">{track.artists[0].name}</Card.Subtitle>
												<Card.Footer> {formatDate(track.album.release_date)}</Card.Footer>
											</Card.Body>
										</Card>
									</Col>
								);
							})
						) : (
							<p>No Tracks Found</p>
						)}
					</Row>
				</Container>

				{/* Selected Album Modal */}
				<Modal show={selectedAlbum !== null} onHide={() => closeModal("album")} centered>
					<Modal.Header className="px-3 py-2" closeButton>
						<Modal.Title>
							{selectedAlbum && (
								<>
									{selectedAlbum.name} by {selectedAlbum.artists[0].name}
								</>
							)}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="p-2 pb-1">
						{selectedAlbum && (
							<>
								<Spotify className="w-100" link={selectedAlbum.external_urls.spotify} />
							</>
						)}
					</Modal.Body>
				</Modal>

				{/* Selected Artists Modal */}
				<Modal show={selectedArtist !== null} onHide={() => closeModal("artist")} centered>
					<Modal.Header className="px-3 py-2" closeButton>
						<Modal.Title>{selectedArtist && <>{selectedArtist.name}</>}</Modal.Title>
					</Modal.Header>
					<Modal.Body className="p-2 pb-1">
						{selectedArtist && (
							<>
								<Spotify className="w-100" link={selectedArtist.external_urls.spotify} />
							</>
						)}
					</Modal.Body>
				</Modal>

				{/* Selected Playlist Modal */}
				<Modal show={selectedPlaylist !== null} onHide={() => closeModal("playlist")} centered>
					<Modal.Header closeButton>
						<Modal.Title>
							{selectedPlaylist && (
								<>
									{selectedPlaylist.name} by {selectedPlaylist.owner}
								</>
							)}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="p-2 pb-1">
						{selectedPlaylist && (
							<>
								<Spotify className="w-100" link={selectedPlaylist.external_urls.spotify} />
							</>
						)}
					</Modal.Body>
				</Modal>

				{/* Selected Tracks Modal */}
				<Modal show={selectedTrack !== null} onHide={() => closeModal("track")} centered>
					<Modal.Header closeButton>
						<Modal.Title>
							{selectedTrack && (
								<>
									{selectedTrack.name} by {selectedTrack.artists[0].name}
								</>
							)}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className="p-2 pb-1">
						{selectedTrack && (
							<>
								<Spotify wide className="w-100" link={selectedTrack.external_urls.spotify} />
							</>
						)}
					</Modal.Body>
				</Modal>
			</div>
		</DarkModeProvider>
	);
}

export default App;
