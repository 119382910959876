import React from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import { DarkModeContext } from "./DarkModeProvider";

function SearchBar({ searchInput, setSearchInput, search }) {
	const darkMode = React.useContext(DarkModeContext);

	return (
		<InputGroup className={`mb-3 ${darkMode.value ? "dark-mode" : "light-mode"}`} size="lg">
			<FormControl
				placeholder="Search..."
				type="input"
				onKeyDown={(event) => {
					if (event.key === "Enter") {
						search();
					}
				}}
				onChange={(event) => setSearchInput(event.target.value)}
			/>
			<Button
				className="d-flex justify-content-center align-items-center"
				onClick={(event) => {
					search();
				}}
			>
				<Search />
			</Button>
		</InputGroup>
	);
}

export default SearchBar;
